import Vue from "vue"
import * as actionHelper from "@/modules/app/helpers/actions";
import SiteVerification from "@/modules/verifications/models/siteVerification";
import Verification from "@/modules/verifications/models/verification";
import BranchVerification from "@/modules/verifications/models/branchVerification";

export default {
    verifyField(store, payload) {
        return new Promise((resolve, reject) => {
            let params = actionHelper.formDataFromProperties(payload)
            return Vue.axios.post('/verification', params).then(value => resolve(Verification.fromJson(value.data.data))).catch(reject)
        })
    },
    getVerificationItems(store, payload) {
        return new Promise((resolve, reject) => {
            let params = actionHelper.formDataFromProperties(payload)

            params.set('_method', 'get')
            return Vue.axios.post('/verification', params).then(
                res => resolve(payload.branch_id ? BranchVerification.fromJson(res.data) : SiteVerification.fromJson(res.data))
            ).catch(err => reject(err))
        })
    },
}
