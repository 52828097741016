import dayjs from 'dayjs';
import Site from "@/modules/sites/models/site";
import Inspection from "@/modules/inspections/models/inspection";

export default class Branch {
    constructor(
        id = null,
        number = '',
        object = '',
        brand_name_en = '',
        brand_name_ar = '',
        area = '',
        address = '',
        full_address = '',
        manager_name = '',
        manager_phone_number = '',
        site = null,
        last_done_inspection = null,
        is_verified = null,
        youtube_url = null,
        created_at = ''
    ) {
        this.id = id
        this.number = number
        this.object = object
        this.brand_name_en = brand_name_en
        this.brand_name_ar = brand_name_ar
        this.area = area
        this.address = address
        this.full_address = full_address
        this.manager_name = manager_name
        this.manager_phone_number = manager_phone_number
        this.site = site
        this.last_done_inspection = last_done_inspection
        this.is_verified = is_verified
        this.youtube_url = youtube_url
        this.is_verified_human_readable = is_verified ? 'Yes' : 'No'
        this.created_at = created_at
    }

    static fromJson(json) {
        return new Branch(
            json.id,
            json.number,
            json.object,
            json.brand_name_en,
            json.brand_name_ar,
            json.area,
            json.address,
            json.area + ', ' + json.address,
            json.manager_name,
            json.manager_phone_number,
            json.site ? Site.fromJson(json.site.data) : null,
            json.last_done_inspection ? Inspection.fromJson(json.last_done_inspection) : null,
            json.is_verified,
            json.youtube_url,
            dayjs(json.created_at).format('YYYY-MM-DD')
        )
    }

    static fromJsonArray(json) {
        let result = [];
        json.forEach(staff => {
                result.push(this.fromJson(staff))
            }
        )
        return result;
    }
}
