import dayjs from 'dayjs';
import Site from "@/modules/sites/models/site";
import Branch from "@/modules/sites/models/branch";
import Staff from "@/modules/staff/models/staff";
import Group from "@/modules/groups/models/group";

export default class Inspection {
    constructor(
        id = null,
        deadline_days = undefined,
        score = 0,
        grade = 0,
        type = '',
        site = new Site(),
        branch = new Branch(),
        inspector = new Staff(),
        group = new Group(),
        address = '',
        inspector_name = '',
        group_name = '',
        appeal_of_inspection = '',
        inspection_due_date = dayjs().format('YYYY-MM-DD'),
        created_at = '',
        object = '',
        status = null,
        resultId = null
    ) {
        this.id = id
        this.deadline_days = deadline_days
        this.score = score
        this.grade = grade
        this.type = type
        this.site = site
        this.branch = branch
        this.inspector = inspector
        this.group = group
        this.address = address
        this.inspector_name = inspector_name
        this.group_name = group_name
        this.appeal_of_inspection = appeal_of_inspection
        this.inspection_due_date = inspection_due_date
        this.created_at = created_at
        this.object = object
        this.status = status
        this.result_id = resultId
    }

    static fromJson(json) {
        return new Inspection(
            json.id,
            json.deadline_days,
            json.score,
            json.grade,
            json.type,
            json.site ? Site.fromJson(json.site.data) : null,
            json.branch ? Branch.fromJson(json.branch.data) : null,
            json.inspector ? Staff.fromJson(json.inspector.data) : null,
            json.group ? Group.fromJson(json.group.data) : null,
            json.branch?.data.area + ', ' + json.branch?.data.address,
            json.inspector ? json.inspector.data.first_name + ' ' + json.inspector.data.last_name : 'Not assigned',
            json.group ? json.group.data.name : 'Not assigned',
            json.appeal_of_inspection,
            dayjs(json.inspection_due_date).format('YYYY-MM-DD'),
            dayjs(json.created_at).format('YYYY-MM-DD'),
            json.object,
            json.status,
            json.result_id
        )
    }

    static fromJsonArray(json) {
        let result = [];
        json.forEach(inspection => {
                result.push(this.fromJson(inspection))
            }
        )
        return result;
    }

    isCRUDable() {
        return this.status !== 'done' && this.status !== 'started'
    }
}
