import Vue from "vue"
import * as actionHelper from "@/modules/app/helpers/actions";
import Site from "@/modules/sites/models/site";
import Branch from "@/modules/sites/models/branch";

export default {
    getAllSites(context, payload) {
        return new Promise((resolve, reject) => {
            let url = actionHelper.prepareGetAllURL(payload, 'site', ['category'])
            return Vue.axios.get(url)
                .then(res => resolve(
                    {
                        items: Site.fromJsonArray(res.data.data),
                        pagination: res.data.meta.pagination
                    }))
                .catch(err => reject(err))
        })
    },
    updateSite(store, payload) {
        let params = actionHelper.formDataFromProperties(payload,
            {'category_id': payload.category?.id},
            ['category', 'user_id', 'logo', 'paci_license_photo', 'commercial_license_photo', 'municipality_license_photo'])

        setImageIfValid(payload, 'logo', params)
        setImageIfValid(payload, 'paci_license_photo', params)
        setImageIfValid(payload, 'commercial_license_photo', params)
        setImageIfValid(payload, 'municipality_license_photo', params)

        params.set('_method', 'patch')
        return Vue.axios.post('/site/' + payload.id, params)
    },
    getSite(store, payload) {
        return new Promise((resolve, reject) => {
            return Vue.axios.get('/site/' + payload.id + '?include=category').then(
                res => resolve(Site.fromJson(res.data.data))
            ).catch(err => reject(err))
        })
    },
    addBranch(store, payload) {
        let params = actionHelper.urlSearchParamsFromProperties(payload)
        return Vue.axios.post('branch', params)
    },
    getAllBranches(context, payload) {
        return new Promise((resolve, reject) => {
            let url = actionHelper.prepareGetAllURL(payload, 'branch')
            return Vue.axios.get(url)
                .then(res => resolve(
                    {
                        items: Branch.fromJsonArray(res.data.data),
                        pagination: res.data.meta.pagination
                    }))
                .catch(err => reject(err))
        })
    },
    updateBranch(store, payload) {
        let params = actionHelper.urlSearchParamsFromProperties(payload, {}, ['full_address'])
        return Vue.axios.patch('/branch/' + payload.id, params)
    },
    deleteBranch(store, payload) {
        return Vue.axios.delete('/branch/' + payload.id)
    },
    getBranch(store, payload) {
        return new Promise((resolve, reject) => {
            return Vue.axios.get('/branch/' + payload.id + '?include=site').then(
                res => resolve(Branch.fromJson(res.data.data))
            ).catch(err => reject(err))
        })
    },
}

const setImageIfValid = (payload, image, params) => {
    if (payload[image] && typeof payload[image] !== 'string')
        params.set(image, payload[image])
}
