import Verification from "@/modules/verifications/models/verification";

export default class BranchVerification {
    constructor(
        id = null,
        object = null,
        number = null,
        brand_name_en = null,
        brand_name_ar = null,
        manager_name = null,
        manager_phone_number = null,
        area = null,
        address = null,
        youtube_url = null,
        latitude = null,
        longitude = null,
    ) {
        this.id = id;
        this.object = object;
        this.number = this.toVerificationModel(number);
        this.brand_name_en = this.toVerificationModel(brand_name_en);
        this.brand_name_ar = this.toVerificationModel(brand_name_ar);
        this.manager_name = this.toVerificationModel(manager_name);
        this.manager_phone_number = this.toVerificationModel(manager_phone_number);
        this.area = this.toVerificationModel(area);
        this.address = this.toVerificationModel(address);
        this.youtube_url = this.toVerificationModel(youtube_url);
        this.latitude = this.toVerificationModel(latitude);
        this.longitude = this.toVerificationModel(longitude);
    }

    static fromJson(json) {
        return new BranchVerification(
            json.id,
            json.object,
            json.number,
            json.brand_name_en,
            json.brand_name_ar,
            json.manager_name,
            json.manager_phone_number,
            json.area,
            json.address,
            json.youtube_url,
            json.latitude,
            json.longitude,
        )
    }

    static fromJsonArray(json) {
        let result = [];
        json.forEach(branchVerification => {
                result.push(this.fromJson(branchVerification))
            }
        )
        return result;
    }

    toVerificationModel(verification) {
        return verification ? Verification.fromJson(verification) : null
    }
}
