import {confirmed, email, max, min, numeric, required, required_if} from 'vee-validate/dist/rules'
import {extend, setInteractionMode} from 'vee-validate'

setInteractionMode('aggressive')

extend('required', {
    ...required,
    message: '{_field_} is required',
})

extend('min', {
    ...min,
    message: '{_field_} may not be less than {length} characters',
})

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
})

extend('email', {
    ...email,
    message: 'Email must be valid',
})

extend('numeric', {
    ...numeric,
    message: '{_field_} must only consist of numbers',
});

extend('confirmed', {
    ...confirmed,
    message: '{_field_} is wrong',
})

extend('required_if', {
    ...required_if,
    message: '{_field_} is required',
})

extend('kuwait_phone', {
    validate: value => {
        const regex = /^(\+965[569]\d{7})$/;
        return value.match(regex)?.length > 0
    },
    message: 'Phone must be valid Kuwait phone number starting with +965'
});
