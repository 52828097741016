import Vue from "vue"
import * as actionHelper from "@/modules/app/helpers/actions";
import Staff from "@/modules/staff/models/staff";
import Role from "@/modules/staff/models/role";

export default {
    createStaff(store, payload) {
        let params = actionHelper.urlSearchParamsFromProperties(payload,
            {'role': payload.role?.name})

        return Vue.axios.post('/staff', params)
    },
    getAllStaff(context, payload) {
        return new Promise((resolve, reject) => {
            let url = actionHelper.prepareGetAllURL(payload, 'staff', 'roles')
            return Vue.axios.get(url)
                .then(res => resolve(
                    {
                        items: Staff.fromJsonArray(res.data.data),
                        pagination: res.data.meta.pagination
                    }))
                .catch(err => reject(err))
        })
    },
    updateStaff(store, payload) {
        let params = actionHelper.urlSearchParamsFromProperties(payload,
            {'role': payload.role?.name})

        return Vue.axios.patch('/staff/' + payload.id, params)
    },
    getAllRoles(context, payload) {
        return new Promise((resolve, reject) => {
            let url = actionHelper.prepareGetAllURL(payload, 'roles')
            return Vue.axios.get(url)
                .then(res => resolve(
                    {
                        items: Role.fromJsonArray(res.data.data),
                        pagination: res.data.meta.pagination
                    }))
                .catch(err => reject(err))
        })
    },
}