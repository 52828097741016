import colors from 'vuetify/lib/util/colors'

export default {
    primary: colors.green.accent3,
    secondary: colors.deepPurple.accent3,
    // accent: '#82B1FF',
    // error: '#FF5252',
    // info: '#2196F3',
    // success: '#4CAF50',
    // warning: '#FFC107',
    anchor: '#2F80ED',
}