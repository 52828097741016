export default [
    {
        path: '/staff',
        name: 'staff',
        component: () => import('../pages/KurccStaffPage'),
        meta: {
            auth: true
        }
    }
]
