import actions from "@/modules/forms/store/actions";
import getters from "@/modules/forms/store/getters";
import mutations from "@/modules/forms/store/mutations";

export default {
    state: () => ({
        form: {
            responseSets: {
                multiple: [
                    {
                        title: null,
                        items: [
                            {
                                label: 'Yes',
                                color: {
                                    hex: '#4CAF50'
                                },
                                score: 1,
                                negative: null
                            },
                            {
                                label: 'No',
                                color: {
                                    hex: '#F44336'
                                },
                                score: 0,
                                negative: true
                            },
                            {
                                label: 'N/A',
                                color: {
                                    hex: '#757575'
                                },
                                score: null,
                                negative: null
                            }
                        ]
                    },
                    {
                        title: null,
                        items: [
                            {
                                label: 'Intact',
                                color: {
                                    hex: '#4CAF50'
                                },
                                score: 1,
                                negative: null
                            },
                            {
                                label: 'Defect',
                                color: {
                                    hex: '#F44336'
                                },
                                score: 0,
                                negative: true
                            },
                            {
                                label: 'N/A',
                                color: {
                                    hex: '#757575'
                                },
                                score: null,
                                negative: null
                            }
                        ]
                    },
                    {
                        title: null,
                        items: [
                            {
                                label: 'Good',
                                color: {
                                    hex: '#4CAF50'
                                },
                                score: 2,
                                negative: null
                            },
                            {
                                label: 'Medium',
                                color: {
                                    hex: '#FF9800'
                                },
                                score: 1,
                                negative: null
                            },
                            {
                                label: 'Bad',
                                color: {
                                    hex: '#F44336'
                                },
                                score: 0,
                                negative: true
                            },
                            {
                                label: 'N/A',
                                color: {
                                    hex: '#757575'
                                },
                                score: null,
                                negative: null
                            }
                        ]
                    }
                ],
                dropdown: [],
            }
        }
    }),
    actions: actions,
    getters: getters,
    mutations: mutations
}
