<template>
    <v-app v-if="$auth.ready()" id="app">
        <kurcc-navigation-drawer v-if="showNavDrawer"/>
        <v-main>
            <v-container class="fill-height align-start" fluid>
                <router-view/>
            </v-container>
        </v-main>
        <kurcc-snackbar/>
    </v-app>
    <v-app v-else id="app">
        <v-main>
            <v-container class="fill-height" fluid>
                <v-row align="center" justify="center">
                    <v-progress-circular indeterminate/>
                </v-row>
            </v-container>
        </v-main>
        <kurcc-snackbar/>
    </v-app>
</template>

<script>
export default {
    name: 'App',
    components: {
        KurccNavigationDrawer: () => import('@/modules/app/components/KurccNavigationDrawer'),
        KurccSnackbar: () => import('@/modules/app/components/KurccSnackbar')
    },
    computed: {
        isLoginPage() {
            return this.$route.name === 'login'
        },
        is404Page() {
            return this.$route.name === 'not-found'
        },
        is403Page() {
            return this.$route.name === 'forbidden'
        },
        showNavDrawer() {
            return !this.isLoginPage && !this.is403Page && !this.is404Page
        },
    }
}
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

.w-100 {
    width: 100% !important;
}

.center-center {
    position: fixed !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

</style>
