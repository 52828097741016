import Vue from 'vue'
import App from './modules/app/pages/App.vue'
import vuetify from './plugins/vuetify';
import store from '@/modules/app/store'
import router from '@/modules/app/router'
import axios from '@/modules/auth/axios'
import VueAxios from 'vue-axios';
import auth from '@websanova/vue-auth';
import configs from '@/modules/auth/configs';
import JsonExcel from "vue-json-excel";
import pluralize from "pluralize"
import lodash from "lodash"
import {ValidationProvider} from 'vee-validate';
import '@/modules/app/validations/validations';
import dayjs from 'dayjs';
import VueFormGenerator from 'vue-form-generator'
import '@/modules/forms/custom-fields-registration'
import DatetimePicker from 'vuetify-datetime-picker'

Vue.use(DatetimePicker)
Vue.router = router;
Vue.use(VueAxios, axios)
Vue.use(auth, configs);
Vue.use(VueFormGenerator)
Vue.component('export', JsonExcel);
Vue.component('ValidationProvider', ValidationProvider);
Vue.prototype.$pluralize = pluralize;
Vue.prototype.$lodash = lodash;
Vue.prototype.$dayjs = dayjs;

Vue.config.productionTip = false

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
