import Vue from "vue"
import {urlSearchParamsFromProperties} from "@/modules/app/helpers/actions";
import Category from "@/modules/categories/models/category";
import * as actionHelper from "@/modules/app/helpers/actions";

export default {
    createCategory(store, payload) {
        let params = urlSearchParamsFromProperties(payload, {},['groups', 'groupNames', 'form'])

        return Vue.axios.post('/category', params)
    },
    getAllCategories(context, payload) {
        return new Promise((resolve, reject) => {
            let url = actionHelper.prepareGetAllURL(payload, 'category', ['groups','form'])
            return Vue.axios.get(url)
                .then(res => resolve(
                    {
                        items: Category.fromJsonArray(res.data.data),
                        pagination: res.data.meta.pagination
                    }))
                .catch(err => reject(err))
        })
    },
    updateCategory(store, payload) {
        let params = urlSearchParamsFromProperties(payload, {}, ['form'])
        return Vue.axios.patch('/category/' + payload.id, params)
    },
    deleteCategory(store, payload) {
        return Vue.axios.delete('/category/' + payload.id)
    }
}
