import actions from "@/modules/verifications/store/actions";
import getters from "@/modules/verifications/store/getters";
import mutations from "@/modules/verifications/store/mutations";

export default {
    state: () => ({}),
    mutations: mutations,
    actions: actions,
    getters: getters
}
