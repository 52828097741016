import {responses} from "@/modules/forms/constants/pre-defined-responses";

export const questionOptions = {
    check: {
        multiChoice: {
            response: responses.multiChoice,
            multiSelect: false,
            textComment: true,
            textCommentAnswer: null,
            photoComment: true,
            photoCommentAnswer: null,
            reportIssue: true,
            reportIssueAnswer: null,
            answerRequired: false
        },
        text: {
            response: responses.text,
            answerRequired: false
        },
        number: {
            response: responses.number,
            answerRequired: false
        },
        check: {
            response: responses.checkbox,
            answerRequired: false
        },
        datetime: {
            response: responses.datetime,
            showDate: true,
            showTime: true,
            answerRequired: false
        },
        photo: {
            response: responses.photo,
            answerRequired: false
        },
        annotation: {
            response: responses.annotation,
            answerRequired: false
        },
        signature: {
            response: responses.signature,
            answerRequired: false
        },
        dropdown: {
            response: responses.dropdown,
            multiSelect: false,
            textComment: true,
            photoComment: true,
            reportIssue: true,
            answerRequired: false
        },
        noResponse: {
            response: responses.noResponse,
            answerRequired: false
        }
    },
    section: {
        weight: 1,
        repeatSection: false,
        parentId: null
    }
}
