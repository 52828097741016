export default [
    {
        path: '/groups',
        name: 'groups',
        component: () => import('../pages/KurccGroupsPage'),
        meta: {
            auth: true
        }
    },
    {
        path: '/groups/:group_id/members',
        name: 'group-members',
        component: () => import('@/modules/staff/pages/KurccStaffPage'),
        props: true,
        meta: {
            auth: true
        }
    },
    {
        path: '/groups/:group_id/inspections',
        name: 'group-inspections',
        component: () => import('@/modules/inspections/pages/KurccInspectionsPage'),
        props: true,
        meta: {
            auth: true
        }
    },
]
