import dayjs from 'dayjs';

export default class Group {
    constructor(
        id = null,
        name = '',
        description = '',
        logo = null,
        enabled = 1,
        object = '',
        created_at = ''
    ) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.logo = logo;
        this.enabled = enabled;
        this.object = object;
        this.created_at = created_at;
    }

    static fromJson(json) {
        return new Group(
            json.id,
            json.name,
            json.description,
            json.logo?.original,
            json.enabled,
            json.object,
            dayjs(json.created_at).format('YYYY-MM-DD'))
    }

    static fromJsonArray(json) {
        let result = [];
        json.forEach(group => {
                result.push(this.fromJson(group))
            }
        )
        return result;
    }
}
