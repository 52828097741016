import dayjs from 'dayjs';
import Verification from "@/modules/verifications/models/verification";

export default class SiteVerification {
    constructor(
        id = null,
        object = null,
        logo = null,
        cover = null,
        name = null,
        email = null,
        category_id = null,
        company_name_en = null,
        company_name_ar = null,
        senior_staff_position = null,
        senior_staff_name = null,
        senior_staff_email = null,
        senior_staff_phone_number = null,
        managing_partner_name = null,
        managing_partner_email = null,
        managing_partner_phone_number = null,
        paci_license_number = null,
        paci_license_expire_date = null,
        paci_license_photo = null,
        commercial_license_number = null,
        commercial_license_expire_date = null,
        commercial_license_photo = null,
        municipality_license_expire_date = null,
        municipality_license_photo = null,
        updated_at = null,
        created_at = null
    ) {
        this.id = id;
        this.object = object;
        this.logo = this.toVerificationModel(logo);
        this.cover = this.toVerificationModel(cover);
        this.name = this.toVerificationModel(name);
        this.email = this.toVerificationModel(email);
        this.category_id = this.toVerificationModel(category_id);
        this.company_name_en = this.toVerificationModel(company_name_en);
        this.company_name_ar = this.toVerificationModel(company_name_ar);
        this.senior_staff_position = this.toVerificationModel(senior_staff_position);
        this.senior_staff_name = this.toVerificationModel(senior_staff_name);
        this.senior_staff_email = this.toVerificationModel(senior_staff_email);
        this.senior_staff_phone_number = this.toVerificationModel(senior_staff_phone_number);
        this.managing_partner_name = this.toVerificationModel(managing_partner_name);
        this.managing_partner_email = this.toVerificationModel(managing_partner_email);
        this.managing_partner_phone_number = this.toVerificationModel(managing_partner_phone_number);
        this.paci_license_number = this.toVerificationModel(paci_license_number);
        this.paci_license_expire_date = this.toVerificationModel(paci_license_expire_date);
        this.paci_license_photo = this.toVerificationModel(paci_license_photo);
        this.commercial_license_number = this.toVerificationModel(commercial_license_number);
        this.commercial_license_expire_date = this.toVerificationModel(commercial_license_expire_date);
        this.commercial_license_photo = this.toVerificationModel(commercial_license_photo);
        this.municipality_license_expire_date = this.toVerificationModel(municipality_license_expire_date);
        this.municipality_license_photo = this.toVerificationModel(municipality_license_photo);
        this.updated_at = updated_at;
        this.created_at = created_at;
    }

    static fromJson(json) {
        return new SiteVerification(
            json.id,
            json.object,
            json.logo,
            json.cover,
            json.name,
            json.email,
            json.category_id,
            json.company_name_en,
            json.company_name_ar,
            json.senior_staff_position,
            json.senior_staff_name,
            json.senior_staff_email,
            json.senior_staff_phone_number,
            json.managing_partner_name,
            json.managing_partner_email,
            json.managing_partner_phone_number,
            json.paci_license_number,
            json.paci_license_expire_date,
            json.paci_license_photo,
            json.commercial_license_number,
            json.commercial_license_expire_date,
            json.commercial_license_photo,
            json.municipality_license_expire_date,
            json.municipality_license_photo,
            dayjs(json.updated_at).format('YYYY-MM-DD'),
            dayjs(json.created_at).format('YYYY-MM-DD')
        )
    }

    static fromJsonArray(json) {
        let result = [];
        json.forEach(siteVerification => {
                result.push(this.fromJson(siteVerification))
            }
        )
        return result;
    }

    toVerificationModel(verification) {
        return verification ? Verification.fromJson(verification) : null
    }
}
