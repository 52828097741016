import dayjs from 'dayjs'
import Category from "@/modules/categories/models/category"

export default class Site {
    constructor(
        id = null,
        user_id = -1,
        name = '',
        object = '',
        email = '',
        company_name_en = '',
        company_name_ar = '',
        enabled = 1,
        managing_partner_name = '',
        managing_partner_email = '',
        managing_partner_phone_number = '',
        senior_staff_position = '',
        senior_staff_name = '',
        senior_staff_email = '',
        senior_staff_phone_number = '',
        created_at = '',
        category = new Category(),
        paci_license_number = '',
        paci_license_expire_date = '',
        paci_license_photo = null,
        commercial_license_number = '',
        commercial_license_expire_date = '',
        commercial_license_photo = null,
        municipality_license_expire_date = '',
        municipality_license_photo = null,
        logo = null,
        branches_count = null,
        is_verified = null,
        cover = null
    ) {
        this.id = id
        this.user_id = user_id
        this.name = name
        this.object = object
        this.email = email
        this.company_name_en = company_name_en
        this.company_name_ar = company_name_ar
        this.enabled = enabled
        this.managing_partner_name = managing_partner_name
        this.managing_partner_email = managing_partner_email
        this.managing_partner_phone_number = managing_partner_phone_number
        this.senior_staff_position = senior_staff_position
        this.senior_staff_name = senior_staff_name
        this.senior_staff_email = senior_staff_email
        this.senior_staff_phone_number = senior_staff_phone_number
        this.created_at = created_at
        this.category = category
        this.paci_license_number = paci_license_number
        this.paci_license_expire_date = paci_license_expire_date
        this.paci_license_photo = paci_license_photo
        this.commercial_license_number = commercial_license_number
        this.commercial_license_expire_date = commercial_license_expire_date
        this.commercial_license_photo = commercial_license_photo
        this.municipality_license_expire_date = municipality_license_expire_date
        this.municipality_license_photo = municipality_license_photo
        this.logo = logo
        this.branches_count = branches_count
        this.is_verified = is_verified
        this.is_verified_human_readable = is_verified ? 'Yes' : 'No'
        this.cover = cover
    }

    static fromJson(json) {
        return new Site(
            json.id,
            json.user_id,
            json.name,
            json.object,
            json.email,
            json.company_name_en,
            json.company_name_ar,
            json.enabled,
            json.managing_partner_name,
            json.managing_partner_email,
            json.managing_partner_phone_number,
            json.senior_staff_position,
            json.senior_staff_name,
            json.senior_staff_email,
            json.senior_staff_phone_number,
            dayjs(json.created_at).format('YYYY-MM-DD'),
            json.category ? Category.fromJson(json.category.data) : null,
            json.paci_license_number,
            dayjs(json.paci_license_expire_date).format('YYYY-MM-DD'),
            json.paci_license_photo?.original,
            json.commercial_license_number,
            dayjs(json.commercial_license_expire_date).format('YYYY-MM-DD'),
            json.commercial_license_photo?.original,
            dayjs(json.municipality_license_expire_date).format('YYYY-MM-DD'),
            json.municipality_license_photo?.original,
            json.logo?.original,
            json.branches_count,
            json.is_verified,
            json.cover?.original
        )
    }

    static fromJsonArray(json) {
        let result = []
        json.forEach(site => {
                result.push(this.fromJson(site))
            }
        )
        return result
    }
}
