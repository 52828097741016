import {
    SET_SNACKBAR_COLOR,
    SET_SNACKBAR_DISPLAY_STATE,
    SET_SNACKBAR_MESSAGE,
    SHOW_SNACKBAR_ERROR,
    SHOW_SNACKBAR_SUCCESS
} from "@/modules/app/store/mutation-types";
import vuetify from '@/plugins/vuetify'

export default {
    [SET_SNACKBAR_DISPLAY_STATE](state, payload) {
        state.snackbar.show = payload
    },
    [SET_SNACKBAR_MESSAGE](state, payload) {
        state.snackbar.message = vuetify.framework.lang.t(payload)
    },
    [SET_SNACKBAR_COLOR](state, payload) {
        state.snackbar.color = payload
    },
    [SHOW_SNACKBAR_SUCCESS](state, payload) {
        this.commit(SET_SNACKBAR_COLOR, 'green')
        this.commit(SET_SNACKBAR_MESSAGE, payload)
        this.commit(SET_SNACKBAR_DISPLAY_STATE, true)
    },
    [SHOW_SNACKBAR_ERROR](state, payload) {
        this.commit(SET_SNACKBAR_COLOR, 'red')
        this.commit(SET_SNACKBAR_MESSAGE, payload)
        this.commit(SET_SNACKBAR_DISPLAY_STATE, true)
    },
}
