import Group from "@/modules/groups/models/group";
import Form from "@/modules/forms/models/form";

export default class Category {
    constructor(
        id = null,
        name = null,
        routine_inspection_duration_days = null,
        deadline_days = null,
        enabled = 1,
        groups = [],
        object = '',
        form = null,
        grades = null
    ) {
        this.id = id
        this.name = name
        this.routine_inspection_duration_days = routine_inspection_duration_days
        this.deadline_days = deadline_days
        this.enabled = enabled
        this.groups = groups
        this.groupNames = groups.length ? groups.map(group => group.name).join(', ') : ''
        this.object = object
        this.form = form
        this.grades = grades
    }

    static fromJson(json) {
        return new Category(
            json.id,
            json.name,
            json.routine_inspection_duration_days,
            json.deadline_days,
            json.enabled,
            json.groups?.data.length > 0 ? Group.fromJsonArray(json.groups.data) : [],
            json.object,
            json.form?.data ? Form.fromJson(json.form.data) : null,
            JSON.parse(json.grades),
        )
    }

    static fromJsonArray(json) {
        let result = [];
        json.forEach(category => {
                result.push(this.fromJson(category))
            }
        )
        return result;
    }
}
