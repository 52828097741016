import {QuestionType} from "@/modules/forms/enums/question-type";
import {
    UPDATE_INSPECTION_FORM_PAGE_QUESTION_FIELD,
    UPDATE_INSPECTION_FORM_PAGE_QUESTION_OPTION_FIELD
} from "@/modules/app/store/mutation-types";
import store from '@/modules/app/store'

export const isQuestionTypeSection = (question) => {
    return question.type === QuestionType.section
}

export const isQuestionTypeCheck = (question) => {
    return question.type === QuestionType.check
}

export const updateQuestionField = (field, value, indexes) => {
    const {
        pageIndex,
        grandparentIndex,
        parentIndex,
        questionIndex
    } = indexes

    store.commit(UPDATE_INSPECTION_FORM_PAGE_QUESTION_FIELD, {
        pageIndex,
        grandparentIndex,
        parentIndex,
        questionIndex,
        field,
        value
    })
}

export const updateQuestionOptionField = (field, value, indexes) => {
    const {
        pageIndex,
        grandparentIndex,
        parentIndex,
        questionIndex
    } = indexes

    store.commit(UPDATE_INSPECTION_FORM_PAGE_QUESTION_OPTION_FIELD, {
        pageIndex,
        grandparentIndex,
        parentIndex,
        questionIndex,
        field,
        value
    })
}
