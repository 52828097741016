import dayjs from 'dayjs';

export default class Verification {
    constructor(
        id = null,
        object = null,
        field_name = null,
        is_verified = null,
        description = null,
        updated_at = null,
        created_at = null
    ) {
        this.id = id;
        this.object = object;
        this.field_name = field_name;
        this.is_verified = is_verified;
        this.description = description;
        this.updated_at = updated_at;
        this.created_at = created_at;
    }

    static fromJson(json) {
        return new Verification(
            json.id,
            json.object,
            json.field_name,
            json.is_verified,
            json.description,
            dayjs(json.updated_at).format('YYYY-MM-DD'),
            dayjs(json.created_at).format('YYYY-MM-DD')
        )
    }

    static fromJsonArray(json) {
        let result = [];
        json.forEach(verification => {
                result.push(this.fromJson(verification))
            }
        )
        return result;
    }
}
