import Vue from "vue";
import KurccFormSignatureResponse from '@/modules/forms/components/custom-fields/KurccFormSignatureResponse';
import KurccFormMultiChoiceResponse from '@/modules/forms/components/custom-fields/KurccFormMultiChoiceResponse';
import KurccFormDropdownResponse from '@/modules/forms/components/custom-fields/KurccFormDropdownResponse';
import KurccFormSection from '@/modules/forms/components/custom-fields/KurccFormSection';
import KurccFormDateTimeResponse from '@/modules/forms/components/custom-fields/KurccFormDateTimeResponse';
import KurccFormText from '@/modules/forms/components/custom-fields/KurccFormText';
import KurccFormNumber from '@/modules/forms/components/custom-fields/KurccFormNumber';
import KurccFormCheckbox from '@/modules/forms/components/custom-fields/KurccFormCheckbox';
import KurccFormImage from '@/modules/forms/components/custom-fields/KurccFormImage';

Vue.component("fieldKurccFormSignatureResponse", KurccFormSignatureResponse);
Vue.component("fieldKurccFormMultiChoiceResponse", KurccFormMultiChoiceResponse);
Vue.component("fieldKurccFormDropdownResponse", KurccFormDropdownResponse);
Vue.component("fieldKurccFormSection", KurccFormSection);
Vue.component("fieldKurccFormDateTimeResponse", KurccFormDateTimeResponse);
Vue.component("fieldKurccFormText", KurccFormText);
Vue.component("fieldKurccFormNumber", KurccFormNumber);
Vue.component("fieldKurccFormCheckbox", KurccFormCheckbox);
Vue.component("fieldKurccFormImage", KurccFormImage);
