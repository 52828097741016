import {en} from 'vuetify/es5/locale'

export default {
    ...en,

    general: {
        name: 'Name',
        enabled: 'Enabled',
        disabled: 'Disabled',
        enable: 'Enable',
        disable: 'Disable',
        status: 'Status',
        actions: 'Actions',
        createdAt: 'Created At',
        verified: 'Verified',
        site: 'Site',
        branch: 'Branch',
        group: 'Group',
        inspector: 'Inspector',
        update: 'Update',
        reset: 'Reset',
        yes: 'Yes',
        no: 'No',
        itemsPerPage: 'Items per page',
        list: 'List',
        page: 'Page',
        of: 'of',
        components: {
            exportTo: 'Export to:',
            export: 'Export',
            viewInspections: 'View Inspections',
            viewMembers: 'View Members',
            viewBranches: 'View Branches',
            viewUnverifiedBranches: 'View Unverified Branches',
            verifySite: 'Verify Site',
            verifyBranch: 'Verify Branch',
            clonedForm: 'Clone Form',
            editSite: 'Edit Site',
            accountSetting: 'Account Setting',
            signOut: 'Sign Out',
            snackbar: {
                created: 'Item Created',
                updated: 'Item Updated',
                deleted: 'Item Deleted',
                networkError: 'A network error occurred',
                notFound: 'Resource not found',
                generalError: 'Something went wrong!'
            },
            navigationDrawer: {
                dashboard: 'Dashboard',
                sites: 'Sites',
                groups: 'Groups',
                categories: 'categories',
                staff: 'Staff',
                forms: 'Forms',
                inspections: 'Inspections',
                verifications: 'Verifications'
            }
        }
    },
    pages: {
        staff: {
            staffId: 'Staff ID',
            firstName: 'First Name',
            lastName: 'Last Name',
            phoneNumber: 'Phone Number',
            email: 'Email',
            role: 'Role',
            addMember: 'Add Member',
            editStaff: 'Edit Staff',
            staffList: 'Staff List',
            deleteFromGroupText: 'Are you sure you want to remove this member from the group?',
            deleteStaffText: 'Are you sure you want to delete this Staff?',
            dataTableHeaders: {
                firstName: 'First Name',
                lastName: 'Last Name',
                phoneNumber: 'Phone Number',
                email: 'Email',
                isAdmin: 'Is Admin',
                staffId: 'Staff ID',
                role: 'Role'
            }
        },
        site: {
            newSite: 'New Site',
            editSite: 'Edit Site',
            siteList: 'Site List',
            rejectionReason: 'Rejection Reason',
            siteDataTableHeaders: {
                siteName: 'Site Name',
                email: 'E-Mail',
                category: 'Category'
            },
            backToSites: 'Back to Sites',
            backToVerifications: 'Back to Verifications',
            backToSiteUnverifiedBranches: 'Back to Unverified Branches List',
            saveAndContinue: 'Save and continue to edit',
            saveChanges: 'Save Changes',
            generalInfo: 'General Information',
            siteName: 'Site Name',
            email: 'Email',
            companyNameEn: 'Company Name (EN)',
            companyNameAr: 'Company Name (AR)',
            category: 'Category',
            logo: 'Logo',
            cover: 'Cover',
            disabledMessage: 'This Site will be',
            viewBranches: 'View Branches',
            viewInspections: 'View Inspections',
            commercialLicense: 'Commercial license',
            licenseNumber: 'License Number',
            licenseExpireDate: 'License Expire Date',
            licensePhoto: 'License Photo',
            seniorStaff: 'Senior staff',
            name: 'Name',
            phoneNumber: 'Phone Number',
            position: 'Position',
            paci: 'PACI',
            paciNumber: 'PACI Number',
            municipality: 'Municipality',
            managingPartner: 'Managing partner',
            branchNumber: 'Branch Number',
            branchNameEn: 'Branch Name (EN)',
            branchNameAr: 'Branch Name (AR)',
            area: 'Area',
            youtubeURL: 'Youtube URL',
            address: 'address',
            managerName: 'Manager Name',
            managerPhoneNumber: 'Manager Phone Number',
            deleteDialogText: 'Are you sure you want to delete this branch?',
            editBranch: 'Edit Branch',
            branchList: 'Branch List',
            dataTableHeaders: {
                branchNumber: 'Branch Number',
                brandNameEn: 'Brand Name (EN)',
                brandNameAr: 'Brand Name (AR)',
                address: 'Address',
                managerName: 'Manager Name',
                managerPhoneNumber: 'Manager Phone Number'
            }
        },
        profile: {
            editProfile: 'Edit Profile',
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            emailRetype: 'Email (Retype)',
            newPassword: 'New Password',
            newPasswordRetype: 'New Password (Retype)',
            back: 'Back'
        },
        inspections: {
            inspectionList: 'Inspection List',
            deleteDialogText: 'Are you sure you want to delete this inspection?',
            editInspection: 'Edit Inspection',
            newRandomInspection: 'New Random Inspection',
            dueDate: 'Due Date',
            deadlineDays: 'Deadline (Days)',
            dataTableHeaders: {
                inspectionSite: 'Inspection Site',
                group: 'Group',
                dueDate: 'Due Date',
                inspector: 'Inspector',
                address: 'Address',
                deadline: 'Deadline (Days)',
                score: 'Score',
                grade: 'Grade',
                type: 'Type',
            }
        },
        groups: {
            groupList: 'Group List',
            deleteDialogText: 'Are you sure you want to delete this group?',
            newGroup: 'New Group',
            editGroup: 'Edit Group',
            member: 'Member',
            groupAdmin: 'Group Admin',
            groupPhoto: 'Group Photo',
            description: 'Description',
            admins: 'Admin(s)',
            users: 'User(s)',
            dataTableHeaders: {
                groupName: 'Group Name',
            }
        },
        dashboard: {
            grades: 'Grades',
            totalInspections: 'Total Inspections',
            websiteVisitors: 'Website Visitors',
            totalInspectors: 'Total Inspectors',
            categories: 'Categories',
            all: 'All',
            today: 'Today',
            thisWeek: 'This Week',
            thisMonth: 'This Month',
            lastThreeMonths: 'Last 3 Months'
        },
        categories: {
            form: 'Form',
            groups: 'Group(s)',
            routineInspectionDuration: 'Routine Inspection Duration (Days)',
            deadline: 'Deadline (Days)',
            categoryList: 'Category List',
            deleteDialogText: 'Are you sure you want to delete this item?',
            editCategory: 'Edit Category',
            newCategory: 'New Category',
            grades: 'Grades',
            addGrade: 'Add Grade',
            gradeName: 'Name',
            gradeValue: 'Minimum required value (%)',
            dataTableHeaders: {
                category: 'Category',
                assignedForms: 'Assigned Forms',
                assignedGroups: 'Assigned Groups',
                routineDuration: 'Routine Duration',
                deadline: 'Deadline',
            }
        },
        auth: {
            forbiddenMessage: 'You entered a forbidden place!',
            dashboard: 'Dashboard',
            takeMeBack: 'Take me back to',
            home: 'Home',
            login: 'Login',
            email: 'Email',
            password: 'password',
            staySignedIn: 'Stay signed in',
            forgotPassword: 'Forgot password?',
        },
        name: {}
    },
    languageSelectorOptions: {
        english: 'English',
        arabic: 'Arabic'
    }
}
