import dayjs from 'dayjs';
import lodash from 'lodash'
import Role from "@/modules/staff/models/role";

export default class Staff {
    constructor(
        id = null,
        first_name = '',
        last_name = '',
        email = '',
        object = '',
        phone_number = '',
        staff_id = '',
        role = new Role(),
        roles_names = '',
        enabled = 1,
        confirmed = false,
        ids_of_groups_staff_is_admin_in = [],
        groups_count = -1,
        created_at = '',
    ) {
        this.id = id;
        this.first_name = first_name;
        this.last_name = last_name;
        this.email = email;
        this.object = object;
        this.phone_number = phone_number;
        this.staff_id = staff_id;
        this.role = role;
        this.roles_names = roles_names;
        this.enabled = enabled;
        this.confirmed = confirmed;
        this.ids_of_groups_staff_is_admin_in = ids_of_groups_staff_is_admin_in;
        this.groups_count = groups_count;
        this.created_at = created_at;
    }

    static fromJson(json) {
        return new Staff(
            json.id,
            json.first_name,
            json.last_name,
            json.email,
            json.object,
            json.phone_number,
            json.staff_id,
            json.roles?.data.length > 0 ? Role.fromJson(json.roles.data[0]) : new Role(),
            Staff.getRoleNames(json),
            json.enabled,
            json.confirmed,
            json.ids_of_groups_staff_is_admin_in,
            json.groups_count,
            dayjs(json.created_at).format('YYYY-MM-DD'))
    }

    static fromJsonArray(json) {
        let result = [];
        json.forEach(staff => {
                result.push(this.fromJson(staff))
            }
        )
        return result;
    }

    static getRoleNames(json) {
        let roleNames = []
        if (json.roles?.data.length > 0)
            json.roles.data.forEach(role => {
                roleNames.push(role.display_name)
            })
        return roleNames.join()
    }

    isAdminInGroup(groupId) {
        return lodash.find(this.ids_of_groups_staff_is_admin_in, id => id === groupId) !== undefined
    }
}
