import Response from '@/modules/forms/models/response'
import {ResponseType} from "@/modules/forms/enums/response-type";

export const responses = {
    multiChoice: new Response('mdi-hexagon-multiple', 'orange', ResponseType["Multiple choice"]),
    text: new Response('mdi-format-text', 'light-blue', ResponseType.Text),
    number: new Response('mdi-numeric', 'purple', ResponseType.Number),
    checkbox: new Response('mdi-check', 'red', ResponseType.Checkbox),
    datetime: new Response('mdi-calendar', 'cyan', ResponseType["Date & Time"]),
    photo: new Response('mdi-camera', 'pink', ResponseType.Photo),
    annotation: new Response('mdi-at', 'yellow', ResponseType.Annotation),
    signature: new Response('mdi-signature', 'green', ResponseType.Signature),
    dropdown: new Response('mdi-form-dropdown', 'indigo', ResponseType.Dropdown),
    noResponse: new Response('mdi-exclamation', 'teal', ResponseType["No response required"])
}
