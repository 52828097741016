export default [
    {
        path: '/verifications',
        name: 'verifications',
        component: () => import('../pages/KurccVerificationsPage'),
        meta: {
            auth: true
        }
    },
    {
        path: '/verifications/sites/:id/verify',
        name: 'site-verification',
        component: () => import('@/modules/verifications/pages/KurccSiteVerificationPage'),
        props: true,
        meta: {
            auth: true
        }
    },
    {
        path: '/verifications/sites/:site_id/branches',
        name: 'site-unverified-branches',
        component: () => import('@/modules/sites/pages/KurccSiteBranchesPage'),
        props: true,
        meta: {
            auth: true
        }
    },
    {
        path: '/verifications/branches/:branch_id/verify',
        name: 'branch-verification',
        component: () => import('@/modules/verifications/pages/KurccBranchVerificationPage'),
        props: true,
        meta: {
            auth: true
        }
    },
]
