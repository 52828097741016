export default class Role {
    constructor(
        id = null,
        name = '',
        description = '',
        display_name = '',
        permissions = '',
        level = '',
        object = '',
    ) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.display_name = display_name;
        this.permissions = permissions;
        this.level = level;
        this.object = object;
    }

    static fromJson(json) {
        return new Role(
            json.id,
            json.name,
            json.description,
            json.display_name,
            json.permissions,
            json.level,
            json.object
        )
    }

    static fromJsonArray(json) {
        let result = [];
        json.forEach(role => {
                result.push(this.fromJson(role))
            }
        )
        return result;
    }
}
