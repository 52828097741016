import Vue from "vue"
import Group from "@/modules/groups/models/group";
import * as actionHelper from "@/modules/app/helpers/actions";
import Staff from "@/modules/staff/models/staff";

export default {
    createGroup(store, payload) {
        let params = actionHelper.formDataFromProperties(payload, {}, ['logo'])

        if (payload.logo && typeof payload.logo !== 'string') params.set('logo', payload.logo)

        return Vue.axios.post('/group', params)
    },
    getAllGroups(context, payload) {
        return new Promise((resolve, reject) => {
            let url = actionHelper.prepareGetAllURL(payload, 'group', 'categories')
            return Vue.axios.get(url)
                .then(res => resolve(
                    {
                        items: Group.fromJsonArray(res.data.data),
                        pagination: res.data.meta.pagination
                    }))
                .catch(err => reject(err))
        })
    },
    updateGroup(store, payload) {
        let params = actionHelper.formDataFromProperties(payload, {}, ['logo'])

        if (payload.logo && typeof payload.logo !== 'string') params.set('logo', payload.logo)

        params.set('_method', 'patch')
        return Vue.axios.post('/group/' + payload.id, params)
    },
    deleteGroup(store, payload) {
        return Vue.axios.delete('/group/' + payload.id)
    },
    getGroup(store, payload) {
        return new Promise((resolve, reject) => {
            return Vue.axios.get('/group/' + payload.id).then(
                res => resolve(Group.fromJson(res.data.data))
            ).catch(err => reject(err))
        })
    },
    addGroupMember(store, payload) {
        let params = actionHelper.urlSearchParamsFromProperties(payload)
        return Vue.axios.post(`group/${payload.id}/member`, params)
    },
    removeGroupMember(store, payload) {
        let params = actionHelper.formDataFromProperties(payload, {
            member_ids: [payload.staff_id],
        }, ['group_id',
            'staff_id'
        ])
        params.set('_method', 'delete')
        return Vue.axios.post(`group/${payload.group_id}/member`, params)
    },
    getAllGroupMembers(context, payload) {
        return new Promise((resolve, reject) => {
            let url = actionHelper.prepareGetAllURL(payload, `staff`)

            if (payload.group_id) url += '&group_id=' + payload.group_id

            return Vue.axios.get(url)
                .then(res => resolve(
                    {
                        items: Staff.fromJsonArray(res.data.data),
                        pagination: res.data.meta.pagination
                    }))
                .catch(err => reject(err))
        })
    },
}
