<template>
    <v-container>
        <kurcc-date-picker v-if="options.showDate && !options.showTime"
                           :date.sync="localItem" :outlined="false" :rules="schema.rules" name="Date"/>

        <kurcc-time-picker v-if="!options.showDate && options.showTime"
                           :outlined="false" :rules="schema.rules" :time.sync="localItem" name="Time"/>

        <validation-provider v-slot="{ errors }" :rules="schema.rules" class="w-100" name="Date & Time">
            <v-datetime-picker v-if="options.showDate && options.showTime" v-model="localItem"
                               label="Date & Time" time-format="HH:mm">
                <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                </template>
            </v-datetime-picker>
            <div v-if="errors.length" class="v-application v-messages error--text v-messages__message">
                {{ errors[0] }}
            </div>
        </validation-provider>
    </v-container>
</template>

<script>
import {updateQuestionField} from "@/modules/forms/helpers/helpers";

export default {
    name: "KurccFormDateTimeResponse",
    props: ['schema'],
    components: {
        KurccDatePicker: () => import('@/modules/app/components/KurccDatePicker'),
        KurccTimePicker: () => import('@/modules/app/components/KurccTimePicker')
    },
    data() {
        return {
            updateQuestionField
        }
    },
    computed: {
        options() {
            return this.schema.item.options
        },
        indexes() {
            return this.schema.indexes
        },
        localItem: {
            get() {
                return this.schema.item.answer
            },
            set(v) {
                this.updateQuestionField('answer', v, this.indexes)
            }
        }
    }
}
</script>

<style scoped>
/deep/ .v-text-field {
    width: 180px;
}
</style>
