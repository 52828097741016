export default [
    {
        path: '/sites',
        name: 'sites',
        component: () => import('../pages/KurccSitesPage'),
        meta: {
            auth: true
        }
    },
    {
        path: '/sites/:id',
        name: 'site',
        component: () => import('../pages/KurccSitePage'),
        props: true,
        meta: {
            auth: true
        }
    },
    {
        path: '/sites/:site_id/inspections',
        name: 'site-inspections',
        component: () => import('@/modules/inspections/pages/KurccInspectionsPage'),
        props: true,
        meta: {
            auth: true
        }
    },
    {
        path: '/sites/:site_id/branches',
        name: 'site-branches',
        component: () => import('@/modules/sites/pages/KurccSiteBranchesPage'),
        props: true,
        meta: {
            auth: true
        }
    },
    {
        path: '/branches/:branch_id/inspections',
        name: 'branch-inspections',
        component: () => import('@/modules/inspections/pages/KurccInspectionsPage'),
        props: true,
        meta: {
            auth: true
        }
    }
]
