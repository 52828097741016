import Question from "@/modules/forms/models/question";

export default class Page {
    constructor(
        title = '',
        questions = [new Question()],
        id = new Date().getTime(),
    ) {
        this.id = id;
        this.questions = questions;
        this.title = title;
    }

    setNewId() {
        this.id = new Date().getTime()
    }
}