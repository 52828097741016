import Vue from "vue"
import * as actionHelper from "@/modules/app/helpers/actions";
import {urlSearchParamsFromProperties} from "@/modules/app/helpers/actions";
import Inspection from "@/modules/inspections/models/inspection";

export default {
    createInspection(store, payload) {
        let params = urlSearchParamsFromProperties(payload,
            {'inspector_id': payload.inspector.id, 'group_id': payload.group.id, 'branch_id': payload.branch.id},
            ['score', 'grade', 'type', 'site', 'branch', 'inspector', 'group', 'address', 'inspector_name', 'appeal_of_inspection',])

        return Vue.axios.post('/inspection', params)
    },
    getAllInspections(store, payload) {
        return new Promise((resolve, reject) => {
            let url = actionHelper.prepareGetAllURL(payload, 'inspection', ['site', 'branch', 'inspector', 'group'])
            return Vue.axios.get(url)
                .then(res => resolve(
                    {
                        items: Inspection.fromJsonArray(res.data.data),
                        pagination: res.data.meta.pagination
                    }))
                .catch(err => reject(err))
        })
    },
    updateInspection(store, payload) {
        let params = urlSearchParamsFromProperties(payload,
            {'inspector_id': payload.inspector.id, 'group_id': payload.group.id},
            ['score', 'grade', 'type', 'site', 'branch', 'inspector', 'group', 'address', 'inspector_name', 'appeal_of_inspection',])

        return Vue.axios.patch('/inspection/' + payload.id, params)
    },
    deleteInspection(store, payload) {
        return Vue.axios.delete('/inspection/' + payload.id)
    }
}