export default [
    {
        path: '/forms',
        name: 'forms',
        component: () => import('../pages/KurccFormsPage'),
        meta: {
            auth: true
        }
    },
    {
        path: '/forms/:form_id',
        name: 'form',
        component: () => import('../pages/KurccFormPage'),
        props: true,
        meta: {
            auth: true
        }
    },
    {
        path: '/form',
        name: 'form-new',
        component: () => import('../pages/KurccFormPage'),
        props: true,
        meta: {
            auth: true
        }
    }
]
