import actions from "@/modules/auth/store/actions";
import getters from "@/modules/auth/store/getters";
import mutations from "@/modules/auth/store/mutations";

export default {
    state: () => {
    },
    actions: actions,
    getters: getters,
    mutations: mutations
}