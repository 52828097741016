import * as actionHelper from "@/modules/app/helpers/actions";
import {urlSearchParamsFromProperties} from "@/modules/app/helpers/actions";
import Vue from "vue";
import Form from "@/modules/forms/models/form";
import {SET_QUESTION_FIELDS_TO_NULL, UPDATE_INSPECTION_FORM} from "@/modules/app/store/mutation-types";
import lodash from "lodash";
import {QuestionType} from "@/modules/forms/enums/question-type";

export default {
    createForm({state, commit}, payload) {
        const maxScore = calculateFormMaxScore(payload.data)
        commit(SET_QUESTION_FIELDS_TO_NULL, {data: state.form, field: 'answer'})
        commit(SET_QUESTION_FIELDS_TO_NULL, {data: state.form, field: 'textCommentAnswer'})
        commit(SET_QUESTION_FIELDS_TO_NULL, {data: state.form, field: 'photoCommentAnswer'})
        commit(SET_QUESTION_FIELDS_TO_NULL, {data: state.form, field: 'reportIssueAnswer'})
        let params = urlSearchParamsFromProperties(payload, {
            data: JSON.stringify(state.form),
            max_score: maxScore > 0 ? maxScore : 0
        }, ['is_draft_datatable_display_ready'])
        return Vue.axios.post('/form', params)
    },
    getAllForms(context, payload) {
        return new Promise((resolve, reject) => {
            let url = actionHelper.prepareGetAllURL(payload, 'form')
            return Vue.axios.get(url)
                .then(res => resolve(
                    {
                        items: Form.fromJsonArray(res.data.data),
                        pagination: res.data.meta.pagination
                    }))
                .catch(err => reject(err))
        })
    },
    getForm(store, payload) {
        return new Promise((resolve, reject) => {
            return Vue.axios.get('/form/' + payload.id).then(
                res => resolve(Form.fromJson(res.data.data))
            ).catch(err => reject(err))
        })
    },
    getInspectionsFilledForm({commit}, payload) {
        return Vue.axios.get('/inspection/' + payload.id + '/result/' + payload.form_id).then(
            res => commit(UPDATE_INSPECTION_FORM, Form.fromJson(res.data.data.form_data))
        ).catch(err => console.log(err))
    },
    deleteForm(store, payload) {
        return Vue.axios.delete('/form/' + payload.id)
    },
    updateForm({commit}, payload) {
        const maxScore = calculateFormMaxScore(payload.data)
        commit(SET_QUESTION_FIELDS_TO_NULL, {data: payload, field: 'answer'})
        commit(SET_QUESTION_FIELDS_TO_NULL, {data: payload, field: 'textCommentAnswer'})
        commit(SET_QUESTION_FIELDS_TO_NULL, {data: payload, field: 'photoCommentAnswer'})
        commit(SET_QUESTION_FIELDS_TO_NULL, {data: payload, field: 'reportIssueAnswer'})
        let params = actionHelper.urlSearchParamsFromProperties(payload, {
            data: JSON.stringify(payload.data),
            max_score: maxScore > 0 ? maxScore : 0
        }, ['is_draft_datatable_display_ready'])
        return Vue.axios.patch('/form/' + payload.id, params)
    }
}

const calculateFormMaxScore = (form) => {
    if (lodash.isNil(form)) return

    let score = 0
    for (let i = 0; i < form.pages.length; i++) {
        score += calculatePageScore(form.pages[i])
    }
    return score
}

const calculatePageScore = (page) => {
    let score = 0
    for (let i = 0; i < page.questions.length; i++) {
        if (page.questions[i].type === QuestionType.section) {
            score += parseInt(page.questions[i].options.weight)
            score += parseInt(calculatePageScore(page.questions[i]))
        }
    }
    return score
}
