import {QuestionType} from "@/modules/forms/enums/question-type";
import QuestionOptions from "@/modules/forms/models/question-options";

export default class Question {
    constructor(
        title = null,
        type = QuestionType.check,
        options = new QuestionOptions().getOptions(),
        questions = [],
        answer = null,
        score = 0,
        id = new Date().getTime()
    ) {
        this.id = id;
        this.title = title;
        this.type = type;
        this.questions = questions;
        this.answer = answer;
        this.score = score;
        this.options = options;
    }

    setNewId() {
        this.id = new Date().getTime()
    }
}
