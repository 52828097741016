<template>
    <v-container class="pa-0">
        <v-row no-gutters>
            <v-col cols="12">
                <kurcc-image-input :hide-details="false" :image.sync="localItem" :name="title" :outlined="false"
                                   :rules="schema.rules" @change="change"/>
            </v-col>
            <v-col v-if="localItem" class="mx-auto" cols="auto">
                <kurcc-image-preview :alt="`${schema.item.title} photo`" :src="localItem"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {abstractField} from "vue-form-generator";
import {updateQuestionField} from "@/modules/forms/helpers/helpers";

export default {
    name: "KurccFormImage",
    mixins: [abstractField],
    components: {
        KurccImageInput: () => import('@/modules/app/components/KurccImageInput'),
        KurccImagePreview: () => import('@/modules/app/components/KurccImagePreview')
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        schema: Object
    },
    data() {
        return {
            updateQuestionField
        }
    },
    computed: {
        title() {
            return this.$lodash.isEmpty(this.schema.item.title) ? 'This field' : this.schema.item.title
        },
        indexes() {
            return this.schema.indexes
        },
        localItem: {
            get() {
                return this.schema.item.answer
            },
            set(v) {
                this.updateQuestionField('answer', v, this.indexes)
            }
        }
    }
}
</script>
