// App
export const SET_SNACKBAR_DISPLAY_STATE = 'SET_SNACKBAR_DISPLAY_STATE'
export const SET_SNACKBAR_MESSAGE = 'SET_SNACKBAR_MESSAGE'
export const SET_SNACKBAR_COLOR = 'SET_SNACKBAR_COLOR'
export const SHOW_SNACKBAR_SUCCESS = 'SHOW_SNACKBAR_SUCCESS'
export const SHOW_SNACKBAR_ERROR = 'SHOW_SNACKBAR_ERROR'

// Forms
export const UPDATE_INSPECTION_FORM = 'UPDATE_INSPECTION_FORM'
export const UPDATE_INSPECTION_FORM_FIELD = 'UPDATE_INSPECTION_FORM_FIELD'
export const UPDATE_INSPECTION_FORM_PAGE_FIELD = 'UPDATE_INSPECTION_FORM_PAGE_FIELD'
export const UPDATE_INSPECTION_FORM_PAGE_QUESTION_FIELD = 'UPDATE_INSPECTION_FORM_PAGE_QUESTION_FIELD'
export const UPDATE_INSPECTION_FORM_PAGE_QUESTION_OPTION_FIELD = 'UPDATE_INSPECTION_FORM_PAGE_QUESTION_OPTION_FIELD'
export const UPDATE_INSPECTION_FORM_PAGE_QUESTIONS = 'UPDATE_INSPECTION_FORM_PAGE_QUESTIONS'
export const INSERT_INSPECTION_FORM_PAGE = 'INSERT_INSPECTION_FORM_PAGE'
export const INSERT_INSPECTION_FORM_QUESTION = 'INSERT_INSPECTION_FORM_QUESTION'
export const CLONE_INSPECTION_FORM_QUESTION = 'CLONE_INSPECTION_FORM_QUESTION'
export const CLONE_INSPECTION_FORM_PAGE_AFTER = 'CLONE_INSPECTION_FORM_PAGE_AFTER'
export const DELETE_INSPECTION_FORM_QUESTION = 'DELETE_INSPECTION_FORM_QUESTION'
export const DELETE_INSPECTION_FORM_PAGE = 'DELETE_INSPECTION_FORM_PAGE'
export const ADD_INSPECTION_FORM_RESPONSE_SET = 'ADD_INSPECTION_FORM_RESPONSE_SET'
export const UPDATE_INSPECTION_FORM_RESPONSE_SET = 'UPDATE_INSPECTION_FORM_RESPONSE_SET'
export const DELETE_INSPECTION_FORM_RESPONSE_SET = 'DELETE_INSPECTION_FORM_RESPONSE_SET'
export const SET_QUESTION_FIELDS_TO_NULL = 'SET_QUESTION_FIELDS_TO_NULL'

// Groups
export const SET_GROUP_MEMBER_TO_ADD = 'SET_GROUP_MEMBER_TO_ADD'
