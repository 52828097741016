<template>
    <v-row align="center" justify="start">
        <v-col cols="12">
            <v-card>
                <vueSignature ref="signature" :disabled="disabled" :h="'200px'" :sigOption="option" :w="'100%'"/>
            </v-card>
        </v-col>
        <v-col class="text-start">
            <v-btn class="mt-2 me-2" color="primary" small @click="save">Save</v-btn>
            <v-btn class="mt-2" color="error" small @click="clear">Clear</v-btn>
        </v-col>
        <v-col v-if="schema.showImageInput" cols="auto">
            <kurcc-image-input :image.sync="dataUrl" :name="`${item.title} Photo`" :only-icon="true" rules=""/>
        </v-col>
        <v-col cols="12">
            <validation-provider ref="provider" v-slot="{ errors }" :name="item.title ? item.title : 'This field'"
                                 :rules="schema.rules" class="w-100">
                <v-text-field v-model="item.answer" :error-messages="errors" class="d-none"/>
                <div v-if="errors.length" class="v-application v-messages error--text v-messages__message">
                    {{ errors[0] }}
                </div>
            </validation-provider>
        </v-col>
    </v-row>
</template>

<script>
import vueSignature from "vue-signature"
import {abstractField} from "vue-form-generator";
import {updateQuestionField} from "@/modules/forms/helpers/helpers";

export default {
    name: "KurccFormSignatureResponse",
    mixins: [abstractField],
    components: {
        KurccImageInput: () => import('@/modules/app/components/KurccImageInput'),
        vueSignature
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        schema: Object
    },
    data() {
        return {
            option: {
                penColor: "rgb(0, 0, 0)",
                backgroundColor: "rgb(255,255,255)"
            },
            dataUrl: undefined,
            updateQuestionField
        };
    },
    computed: {
        item() {
            return this.schema.item
        },
        indexes() {
            return this.schema.indexes
        },
        localItem: {
            get() {
                return this.schema.item.answer
            },
            set(v) {
                this.updateQuestionField('answer', v, this.indexes)
            }
        }
    },
    watch: {
        dataUrl: 'fromDataURL',
        localItem: {
            handler: function (v) {
                setTimeout(() => this.$refs.signature.fromDataURL(this.$lodash.cloneDeep(v), 0.5))
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        save() {
            // let jpeg = this.$refs.signature.save('image/jpeg')
            // let svg = this.$refs.signature.save('image/svg+xml');
            this.localItem = this.$refs.signature.save()
            this.validate()
        },
        clear() {
            this.$refs.signature.clear();
            this.localItem = null
            this.validate()
        },
        validate() {
            this.$refs.provider.validate()
        },
        fromDataURL() {
            let img = null
            if (this.dataUrl)
                img = URL.createObjectURL(this.dataUrl)
            else
                img = this.$lodash.cloneDeep(this.dataUrl)

            this.$refs.signature.fromDataURL(img);
        }
    }
}
</script>
