import {ResponseType} from "@/modules/forms/enums/response-type";
import lodash from 'lodash'

export default class Response {
    constructor(
        icon,
        iconColor,
        type,
        id = new Date().getTime(),
    ) {
        this.id = id;
        this.icon = icon;
        this.iconColor = iconColor;
        this.type = type;
        import('@/modules/app/store').then(
            value => {
                if (type === ResponseType['Multiple choice']) {
                    this.responseSet = value.default.state.forms.form.responseSets.multiple[0]
                }
            }
        )
        this.title = lodash.findKey(ResponseType, item => item === this.type)
    }
}
