import actions from "@/modules/groups/store/actions";
import getters from "@/modules/groups/store/getters";
import mutations from "@/modules/groups/store/mutations";

export default {
    state: () => ({
        groupMemberManipulate: undefined
    }),
    mutations: mutations,
    actions: actions,
    getters: getters
}