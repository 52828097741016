export default [
    {
        path: '/inspections',
        name: 'inspections',
        component: () => import('../pages/KurccInspectionsPage'),
        meta: {
            auth: true
        }
    }
]
