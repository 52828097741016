export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../pages/KurccLoginPage'),
        meta: {
            auth: false
        }
    },
    {
        path: '/403',
        name: 'forbidden',
        component: () => import('@/modules/auth/pages/KurccForbiddenPage'),
    },
    {
        path: '/404',
        name: 'not-found',
        component: () => import('@/modules/auth/pages/KurccNotFoundPage'),
    }
]
