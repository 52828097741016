export default [
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../pages/KurccDashboardPage'),
        meta: {
            auth: true
        }
    }
]
