import Page from "@/modules/forms/models/page";
import Question from "@/modules/forms/models/question";
import dayjs from "dayjs";
import store from '@/modules/app/store'

export default class Form {
    constructor(
        id = null,
        title = 'Form 01',
        description = null,
        pages = [new Page(null, [new Question()], new Date().getTime())],
        object = null,
        enabled = null,
        is_draft = null,
        created_at = null,
        responseSets = store.state.forms.form.responseSets,
        version = 1
    ) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.pages = pages;
        this.object = object;
        this.enabled = enabled;
        this.is_draft = is_draft;
        this.is_draft_datatable_display_ready = is_draft ? 'Yes' : 'No';
        this.created_at = created_at;
        this.responseSets = responseSets;
        this.version = version;
    }

    static fromJson(json) {
        let parsedForm
        if (json.data) {
            parsedForm = JSON.parse(json.data)
        } else {
            parsedForm = JSON.parse(json)
        }
        return new Form(
            json.id,
            parsedForm.title,
            parsedForm.description,
            parsedForm.pages,
            json.object,
            json.enabled,
            json.is_draft,
            dayjs(json.created_at).format('YYYY-MM-DD'),
            parsedForm.responseSets,
            json.version
        )
    }

    static fromJsonArray(json) {
        let result = [];
        json.forEach(form => {
                result.push(this.fromJson(form))
            }
        )
        return result;
    }
}
