import {
    ADD_INSPECTION_FORM_RESPONSE_SET,
    CLONE_INSPECTION_FORM_PAGE_AFTER,
    CLONE_INSPECTION_FORM_QUESTION,
    DELETE_INSPECTION_FORM_PAGE,
    DELETE_INSPECTION_FORM_QUESTION,
    DELETE_INSPECTION_FORM_RESPONSE_SET,
    INSERT_INSPECTION_FORM_PAGE,
    INSERT_INSPECTION_FORM_QUESTION,
    SET_QUESTION_FIELDS_TO_NULL,
    UPDATE_INSPECTION_FORM,
    UPDATE_INSPECTION_FORM_FIELD,
    UPDATE_INSPECTION_FORM_PAGE_FIELD,
    UPDATE_INSPECTION_FORM_PAGE_QUESTION_FIELD,
    UPDATE_INSPECTION_FORM_PAGE_QUESTION_OPTION_FIELD,
    UPDATE_INSPECTION_FORM_PAGE_QUESTIONS,
    UPDATE_INSPECTION_FORM_RESPONSE_SET,
} from "@/modules/app/store/mutation-types";
import Question from "@/modules/forms/models/question";
import lodash from 'lodash'
import {QuestionType} from "@/modules/forms/enums/question-type";
import Page from "@/modules/forms/models/page";
import {ResponseType} from "@/modules/forms/enums/response-type";
import QuestionOptions from "@/modules/forms/models/question-options";
import {questionOptions} from "@/modules/forms/constants/pre-defined-question-options";

export default {
    [UPDATE_INSPECTION_FORM](state, form) {
        state.form = form
    },
    [UPDATE_INSPECTION_FORM_FIELD](state, payload) {
        state.form[payload.field] = payload.value
    },
    [UPDATE_INSPECTION_FORM_PAGE_FIELD](state, payload) {
        (state.form.pages[payload.pageIndex])[payload.field] = payload.value
    },
    [UPDATE_INSPECTION_FORM_PAGE_QUESTION_FIELD](state, payload) {
        const {pageIndex, grandparentIndex, parentIndex, questionIndex, field, value} = payload;
        if (existAndGteZero(grandparentIndex)) {
            (state.form.pages[pageIndex].questions[grandparentIndex].questions[parentIndex].questions[questionIndex])[field] = value
        } else if (existAndGteZero(parentIndex)) {
            (state.form.pages[pageIndex].questions[parentIndex].questions[questionIndex])[field] = value
        } else {
            (state.form.pages[pageIndex].questions[questionIndex])[field] = value
        }
    },
    [UPDATE_INSPECTION_FORM_PAGE_QUESTION_OPTION_FIELD](state, payload) {
        const {pageIndex, grandparentIndex, parentIndex, questionIndex, field, value} = payload;
        if (existAndGteZero(grandparentIndex)) {
            (state.form.pages[pageIndex].questions[grandparentIndex].questions[parentIndex].questions[questionIndex]).options[field] = value
        } else if (existAndGteZero(parentIndex)) {
            (state.form.pages[pageIndex].questions[parentIndex].questions[questionIndex]).options[field] = value
        } else {
            (state.form.pages[pageIndex].questions[questionIndex]).options[field] = value
        }
    },
    [UPDATE_INSPECTION_FORM_PAGE_QUESTIONS](state, payload) {
        state.form.pages[payload.pageIndex].questions = payload.questions
    },
    [INSERT_INSPECTION_FORM_QUESTION](state, payload) {
        const {pageIndex, parentIndex, grandparentIndex, insertIndex, questionType} = payload
        let question = new Question()

        if (questionType === QuestionType.section) {
            question.options = new QuestionOptions(questionOptions.section).getOptions()
            question.type = QuestionType.section
            question.questions = [new Question()]
        }
        if (existAndGteZero(grandparentIndex)) {
            state.form.pages[pageIndex].questions[grandparentIndex].questions[parentIndex].questions.splice(insertIndex, 0, question)
        } else if (existAndGteZero(parentIndex)) {
            state.form.pages[pageIndex].questions[parentIndex].questions.splice(insertIndex, 0, question)
        } else {
            state.form.pages[pageIndex].questions.splice(insertIndex, 0, question)
        }
    },
    [CLONE_INSPECTION_FORM_QUESTION](state, payload) {
        const {pageIndex, question, insertIndex, parentIndex, grandparentIndex, parentId} = payload
        let q = lodash.cloneDeep(question)
        q.id = new Date().getTime()
        if (parentId) {
            q.options.parentId = parentId
            q.options.repeatSection = false
        }
        let page = state.form.pages[pageIndex]

        if (existAndGteZero(grandparentIndex)) {
            page.questions[grandparentIndex].questions[parentIndex].questions.splice(insertIndex, 0, q)
        } else if (existAndGteZero(parentIndex)) {
            page.questions[parentIndex].questions.splice(insertIndex, 0, q)
        } else {
            page.questions.splice(payload.insertIndex, 0, q)
        }
    },
    [DELETE_INSPECTION_FORM_QUESTION](state, payload) {
        const {pageIndex, parentIndex, grandparentIndex, questionIndex} = payload
        if (existAndGteZero(grandparentIndex)) {
            state.form.pages[pageIndex].questions[grandparentIndex].questions[parentIndex].questions.splice(questionIndex, 1)
        } else if (existAndGteZero(parentIndex)) {
            state.form.pages[pageIndex].questions[parentIndex].questions.splice(questionIndex, 1)
        } else {
            state.form.pages[pageIndex].questions.splice(questionIndex, 1)
        }
    },
    [DELETE_INSPECTION_FORM_PAGE](state, payload) {
        state.form.pages.splice(payload.pageIndex, 1)
    },
    [CLONE_INSPECTION_FORM_PAGE_AFTER](state, payload) {
        let page = state.form.pages[payload.pageIndex]
        let clonedPage = lodash.cloneDeep(page)
        clonedPage.id = new Date().getTime()
        let insertIndex = payload.pageIndex + 1
        state.form.pages.splice(insertIndex, 0, clonedPage)
    },
    [INSERT_INSPECTION_FORM_PAGE](state) {
        state.form.pages.push(new Page())
    },
    [ADD_INSPECTION_FORM_RESPONSE_SET](state, payload) {
        const {set, type} = payload

        if (type === ResponseType.Dropdown)
            state.form.responseSets.dropdown.push(set)
        if (type === ResponseType["Multiple choice"])
            state.form.responseSets.multiple.push(set)
    },
    [UPDATE_INSPECTION_FORM_RESPONSE_SET](state, payload) {
        const {set, type, index} = payload

        if (type === ResponseType.Dropdown)
            state.form.responseSets.dropdown[index] = set
        if (type === ResponseType["Multiple choice"])
            state.form.responseSets.multiple[index] = set
    },
    [DELETE_INSPECTION_FORM_RESPONSE_SET](state, payload) {
        const {index, type} = payload

        if (type === ResponseType.Dropdown)
            state.form.responseSets.dropdown.splice(index, 1)
        if (type === ResponseType["Multiple choice"])
            state.form.responseSets.multiple.splice(index, 1)
    },
    [SET_QUESTION_FIELDS_TO_NULL](state, payload) {
        if (lodash.isNil(payload.data)) return

        Object.keys(payload.data).forEach(key => {
            if (key === payload.field)
                payload.data[key] = null

            if (typeof payload.data[key] === 'object') {
                this.commit(SET_QUESTION_FIELDS_TO_NULL, {data: payload.data[key], field: payload.field})
            }
        })
    }
}

const existAndGteZero = value => {
    return !lodash.isNil(value) && lodash.gte(value, 0)
}
